import React, { useState } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { darkTheme, theme } from './theme';

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ThemeWrapper = ({ children }: Props) => {
  const [darkMode] = useState(localStorage.getItem('darkMode') !== null || false);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkMode ? darkTheme : theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeWrapper;

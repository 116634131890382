import React, { createContext, useContext, useState, useRef } from 'react';
import ConfirmDialog, { ConfirmOptions } from '@components/ConfirmDialog';
import Alert from '@mui/material/Alert';
import { theme } from '../theme';

/* eslint-disable @typescript-eslint/unbound-method */
const ConfirmServiceContext = createContext<(options: ConfirmOptions) => Promise<void>>(
  Promise.reject,
);
/* eslint-enable @typescript-eslint/unbound-method */

type ProviderProps = {
  children: JSX.Element;
};

export const ConfirmServiceProvider = ({ children }: ProviderProps) => {
  const [state, setState] = useState<ConfirmOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirm = (options: ConfirmOptions) => {
    setState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleCancel = () => {
    if (state?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setState(null);
  };

  const handleOk = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setState(null);
  };

  return (
    <>
      <ConfirmServiceContext.Provider value={openConfirm}>
        {children}
        {state?.showAlert && (
          <Alert
            sx={{
              position: 'absolute',
              bottom: 20,
              left: '50%',
              transform: 'translate(-50%, 0)',
              zIndex: 9999,
              color: theme.palette.primary.black,
            }}
            severity="error"
          >
            {state?.alertContent}
          </Alert>
        )}
      </ConfirmServiceContext.Provider>
      {state !== null && (
        <ConfirmDialog
          open
          title={state?.title as string}
          content={state?.content as any}
          cancelTitle={state?.cancelTitle}
          okTitle={state?.okTitle}
          flexButtons={state?.flexButtons}
          handleOk={handleOk}
          handleCancel={handleCancel}
          customClass={state?.customClass}
        />
      )}
    </>
  );
};

export const useConfirm = () => useContext(ConfirmServiceContext);

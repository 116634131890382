import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { CloseIcon } from '../Icons';
import { classes, StyledDialog, StyledTitleWrapper, StyledTitle } from './styled';
import Typography from '@mui/material/Typography';

type DialogTitleProps = {
  title?: string | null | JSX.Element;
  subtitle?: string;
  onClose?: () => void;
  sx?: SxProps<Theme>;
  showCloseIcon?: boolean;
};

type DialogCustomProps = {
  blurredBackground?: boolean;
};

export const Dialog = ({
  open,
  onClose,
  onSubmit,
  children,
  blurredBackground = false,
  ...rest
}: DialogProps & DialogCustomProps) => {
  return (
    <StyledDialog
      className={blurredBackground ? 'blurred' : ''}
      open={open}
      onKeyDown={(e) => {
        e.key === 'Escape' && onClose && onClose(e, 'escapeKeyDown');
        e.key === 'Enter' && onSubmit && onSubmit(e);
      }}
      disableRestoreFocus
      {...rest}
    >
      {children}
    </StyledDialog>
  );
};

export const DialogTitle = ({
  title,
  subtitle,
  onClose,
  sx,
  showCloseIcon = true,
}: DialogTitleProps) => {
  return (
    <StyledTitleWrapper sx={sx}>
      <StyledTitle>
        {title}
        {showCloseIcon && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
            data-testid="close-icon"
          >
            <CloseIcon />
          </IconButton>
        )}
      </StyledTitle>
      {subtitle?.length && <Typography variant="body2">{subtitle}</Typography>}
    </StyledTitleWrapper>
  );
};

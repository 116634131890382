import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PREFIX = 'ErrorScreen';

export const classes = {
  root: `${PREFIX}-root`,
  errorBlock: `${PREFIX}-errorBlock`,
  message: `${PREFIX}-message`,
};

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#FFF',
  zIndex: 100,

  [`& .${classes.errorBlock}`]: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.grey[200],
    textAlign: 'center',
    maxWidth: 400,
  },

  [`& .${classes.message}`]: {
    display: 'block',
    textAlign: 'center',
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(0.5),
    background: '#f2f2f2',
    padding: theme.spacing(0.5, 1, 0.5, 1),
    marginTop: theme.spacing(1),
  },
}));

import clsx from 'clsx';
import { classes } from '../styled';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useNavigate } from 'react-router-dom';
import NavDropdown from './NavDropdown';
import { NavTooltip } from './NavTooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export type NavItemProps = {
  title: string;
  icon: JSX.Element;
  to: string;
  autoHideTitles: string;
  onClick?: () => void;
  newUpdates?: string;
  extraClassName?: string;
  isDropdown?: boolean;
  links?: any;
  expanded?: boolean;
  collapsedStyles?: string;
};

const NavItem = ({
  title,
  icon,
  to,
  newUpdates,
  extraClassName = '',
  onClick,
  autoHideTitles,
  isDropdown,
  links = [],
  expanded = false,
  collapsedStyles = '',
}: NavItemProps) => {
  const navigate = useNavigate();
  return isDropdown ? (
    <NavDropdown
      expanded={expanded}
      autoHideTitles={autoHideTitles}
      collapsedStyles={collapsedStyles}
      links={links}
      icon={icon}
      title={title}
    />
  ) : (
    <>
      <NavTooltip placement="right" disableHoverListener={expanded} arrow title={<p>{title}</p>}>
        <Box>
          <NavLink
            data-testid={title}
            className={({ isActive }) =>
              clsx(classes.menuItem, extraClassName, isActive && classes.activeMenuItem)
            }
            to={to}
            onClick={onClick}
          >
            <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
            <ListItemText className={autoHideTitles} primary={title} />
          </NavLink>
        </Box>
      </NavTooltip>
      {newUpdates && expanded && (
        <Button fullWidth variant="contained" onClick={() => navigate('/models')}>
          {newUpdates}
        </Button>
      )}
    </>
  );
};

export { NavItem };

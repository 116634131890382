import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import FullPageLoading from '@components/FullPageLoading';

type AppState = {
  appState: {
    returnTo: string;
    from: string;
  };
};

const LoginScreen = () => {
  const [currentSearchParams] = useSearchParams();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const state = location.state as AppState;
  if (state?.appState) {
    state.appState.from = location.pathname;
  }
  const invitation = currentSearchParams.get('invitation');
  const organization = currentSearchParams.get('organization');
  const organizationName = currentSearchParams.get('organization_name');

  useEffect(() => {
    if (!isAuthenticated) {
      const query = {
        ...(invitation && { invitation }),
        ...(organization && { organization }),
        ...(organizationName && { organization_name: organizationName }),
      };
      loginWithRedirect({ ...state, ...query });
    }
  }, [isAuthenticated, loginWithRedirect, state, invitation, organization, organizationName]);

  return <FullPageLoading />;
};

export default LoginScreen;

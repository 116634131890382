import Typography from '@mui/material/Typography';
import { DialogTitle } from '@components/Dialog';
import { DialogContent } from '@mui/material';
import { partial } from 'lodash';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';

import { classes, StyledDialog } from '../styled';

type Props = {
  isDisclaimerModalOpened: boolean;
  setDisclaimerModalOpened: (value: boolean) => void;
  handleAccept: () => void;
};

const DisclaimerDialog = ({
  isDisclaimerModalOpened,
  setDisclaimerModalOpened,
  handleAccept,
}: Props) => {
  const { t } = useTranslation(['disclaimer']);

  return (
    <StyledDialog
      open={isDisclaimerModalOpened}
      PaperProps={{ className: classes.dialog }}
      maxWidth="md"
    >
      <DialogTitle
        title={t('Please read and accept')}
        onClose={partial(setDisclaimerModalOpened, false)}
        showCloseIcon={false}
      />
      <DialogContent className={classes.dialogContent}>
        <Typography>{t('disclaimer long')}</Typography>
      </DialogContent>
      <Button variant="contained" color="primary" onClick={handleAccept}>
        {t('Accept')}
      </Button>
    </StyledDialog>
  );
};

export { DisclaimerDialog };

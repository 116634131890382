import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

export const StyledButton = styled(MuiButton)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 500,
  whiteSpace: 'nowrap',
  borderRadius: 2,
  '& svg': {
    fontSize: '14px !important',
  },
}));

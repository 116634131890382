import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { SelectOption, WarmingLevelOption } from '@models';
import { useUserProperties } from '@context/userProperties';
import useGetToken from '@hooks/useGetToken';
import { getModel, getScenarios, patchSelectedScenarios } from '@api';
import { defaultScenarioType } from '@constants';
import FullPageLoading from '@components/FullPageLoading';

type ScenarioMapping = {
  [key: string]: {
    name: string;
    description: string | null;
    sortOrder: number;
    warming_level: WarmingLevelOption[];
    warning?: string | null;
  };
};

type ContextType = {
  scenarioOptions: SelectOption[];
  selectedScenarios: string[];
  scenarioMapping: ScenarioMapping;
  handleScenarioChange: (values: string[], payloadKey: string) => void;
  loadingScenarios: boolean;
  errorLoadingScenarios: string;
  scenariosChanging: boolean;
  scenarioType: string;
  setScenarioType: (scenarioType: string) => void;
};

const ScenarioSelectContext = createContext<ContextType>({
  scenarioOptions: [],
  selectedScenarios: [],
  scenarioMapping: {},
  handleScenarioChange: () => undefined,
  loadingScenarios: false,
  errorLoadingScenarios: '',
  scenariosChanging: false,
  scenarioType: defaultScenarioType,
  setScenarioType: () => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const ScenarioSelectProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const { t } = useTranslation(['common']);
  const {
    preferences: { scenarios, model_version },
    setPreferences,
  } = useUserProperties();
  const [scenarioOptions, setScenarioOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<string[]>(scenarios.map((id) => `${id}`));
  const [loading, setLoading] = useState<boolean>(false);
  const [initialRendered, setInitialRendered] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [scenarioMapping, setScenarioMapping] = useState<ScenarioMapping>({});
  const [scenariosChanging, setScenariosChanging] = useState<boolean>(false);
  const [scenarioType, setScenarioType] = useState(defaultScenarioType);

  const handleScenarioChange = useCallback(
    async (values: string[], payloadKey: string) => {
      if (!scenariosChanging) {
        const token = await getToken();
        setScenariosChanging(true);
        const payload = { [payloadKey]: values.map((id) => +id) };
        patchSelectedScenarios(token, payload)
          .then((data) => setPreferences(data))
          .finally(() => setScenariosChanging(false));
      }
    },
    [getToken, setPreferences, scenariosChanging],
  );

  const fetchScenarios = useCallback(async () => {
    const token = await getToken();
    setLoading(true);
    getScenarios(token, { model_version, scenario_type: scenarioType })
      .then((res) => {
        const sortedScenarios = [...res].sort(
          (a, b) => (a?.sort_order ?? 0) - (b?.sort_order ?? 0),
        );
        setInitialRendered(true);
        setScenarioOptions(
          sortedScenarios.map((sc) => ({
            name: sc.name,
            value: `${sc.id}`,
            category: sc.category,
            warning: sc.warning,
          })),
        );
        setScenarioMapping(
          res.reduce((acc: ScenarioMapping, sc) => {
            acc[`${sc.id}`] = {
              name: sc.name,
              description: sc.description,
              sortOrder: sc.sort_order ?? 0,
              warming_level: sc.warming_level.sort((a, b) => a.year - b.year),
            };
            return acc;
          }, {}),
        );
      })
      .catch((err) => setError(err?.data?.error_message ?? t('Something went wrong')))
      .finally(() => setLoading(false));
  }, [getToken, model_version, scenarioType, t]);

  const value = useMemo(
    () => ({
      scenarioOptions,
      selectedScenarios: selected,
      scenarioMapping,
      handleScenarioChange,
      loadingScenarios: loading,
      errorLoadingScenarios: error,
      scenariosChanging,
      scenarioType,
      setScenarioType,
    }),
    [
      error,
      handleScenarioChange,
      loading,
      scenarioMapping,
      scenarioOptions,
      scenarioType,
      scenariosChanging,
      selected,
    ],
  );

  useEffect(() => {
    fetchScenarios();
  }, [fetchScenarios]);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (!scenarios.length) {
        const { default_scenarios } = await getModel(token, model_version);
        patchSelectedScenarios(token, { scenarios: default_scenarios });
      } else if (
        !isEqual(
          scenarios,
          selected.map((id) => +id),
        )
      ) {
        setSelected(scenarios.map((id) => `${id}`));
      }
    })();
  }, [getToken, scenarios, model_version, selected]);

  return (
    <ScenarioSelectContext.Provider value={value}>
      {loading && !initialRendered ? <FullPageLoading /> : children}
    </ScenarioSelectContext.Provider>
  );
};

export const useScenarioSelect = () => useContext(ScenarioSelectContext);

export const SET_UPLOAD_DATA = 'SET_UPLOAD_DATA';
export const SET_MANUAL_INPUT_ENABLED = 'SET_MANUAL_INPUT_ENABLED';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_REPLACE_REQUESTED = 'SET_REPLACE_REQUESTED';
export const SHOW_REPLACE_DIALOG = 'SHOW_REPLACE_DIALOG';
export const SET_EDITABLE_ROW_INDEX = 'SET_EDITABLE_ROW_INDEX';
export const CREATE_NEW_ROW = 'CREATE_NEW_ROW';
export const DROP_FILE = 'DROP_FILE';
export const DELETE_ROW = 'DELETE_ROW';
export const CREATED_CORPORATE_ID = 'CREATED_CORPORATE_ID';
export const SORT_DATA = 'SORT_DATA';
export const SET_MULTIPLE_UPLOAD = 'SET_MULTIPLE_UPLOAD';
export const RESET_DATA = 'RESET_DATA';
export const SET_COLUMN_ERRORS = 'SET_COLUMN_ERRORS';
export const DISABLE_DOWNLOAD_SAVED_DATA = 'DISABLE_DOWNLOAD_SAVED_DATA';
export const SET_READ_ONLY_MODE = 'SET_READ_ONLY_MODE';

export const setUploadedData = (value: any, storeInitData?: boolean) => ({
  type: SET_UPLOAD_DATA,
  value,
  storeInitData,
});
export const setManualInputEnabled = (value: boolean) => ({
  type: SET_MANUAL_INPUT_ENABLED,
  value,
});
export const setMultipleUpload = (value: boolean) => ({ type: SET_MULTIPLE_UPLOAD, value });
export const setError = (title: string, message: string[] | string) => ({
  type: SET_ERROR,
  title,
  message,
});
export const setLoading = (value: boolean) => ({ type: SET_LOADING, value });
export const setReplaceRequested = (value: boolean) => ({ type: SET_REPLACE_REQUESTED, value });
export const setShowReplaceDialog = (value: boolean) => ({ type: SHOW_REPLACE_DIALOG, value });
export const setEditableRowIndex = (value: number) => ({ type: SET_EDITABLE_ROW_INDEX, value });
export const onCreateNewRow = () => ({ type: CREATE_NEW_ROW });
export const onDropFile = (value: any) => ({ type: DROP_FILE, value });
export const onDeleteRow = (index: number) => ({ type: DELETE_ROW, index });
export const setCreatedCorporateId = (id: string | null) => ({ type: CREATED_CORPORATE_ID, id });
export const setSortData = (sorting: any, fieldType: string) => ({
  type: SORT_DATA,
  sorting,
  fieldType,
});
export const setColumnErrors = (value: any) => ({ type: SET_COLUMN_ERRORS, value });
export const resetData = () => ({ type: RESET_DATA });
export const setDisableDownloadSavedData = (value: boolean) => ({
  type: DISABLE_DOWNLOAD_SAVED_DATA,
  value,
});
export const setReadOnlyMode = (value: boolean) => ({ type: SET_READ_ONLY_MODE, value });

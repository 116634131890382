import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PREFIX = 'Sidebar';

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  subWrapper: `${PREFIX}-subWrapper`,
  scrollbars: `${PREFIX}-scrollbars`,
  navWrapper: `${PREFIX}-navWrapper`,
  logo: `${PREFIX}-logo`,
  category: `${PREFIX}-category`,
  menuItem: `${PREFIX}-menuItem`,
  activeMenuItem: `${PREFIX}-activeMenuItem`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  hiddenText: `${PREFIX}-hiddenText`,
  account: `${PREFIX}-account`,
  header: `${PREFIX}-header`,
  dropdownItem: `${PREFIX}-dropdownItem`,
  activeDropdown: `${PREFIX}-nav-dropdown-active`,
  company: `${PREFIX}-company`,
  companyLogo: `${PREFIX}-companyLogo`,
  companyName: `${PREFIX}-companyName`,
  expandButton: `${PREFIX}-expand-button`,
  flag: `${PREFIX}-flag`,
  hidden: `${PREFIX}-hidden`,
  dialogContent: `${PREFIX}-dialogContent`,
  shortDisclaimer: `${PREFIX}-shortDisclaimer`,
  subNavItems: `${PREFIX}-subNavItems`,
  dropdownTitleBox: `${PREFIX}-dropdownTitleBox`,
  disclaimerIcon: `${PREFIX}-disclaimerIcon`,
};

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  boxSizing: 'border-box',
  overflowY: 'auto',
  '&.collapsed': {
    flexBasis: 56,
    minWidth: '56px',
    display: 'flex',
    padding: `${theme.spacing(3)} 0`,
    alignItems: 'center',

    transitionDuration: '0.31s',
    transitionProperty: 'flex-basis',

    '& .Sidebar-logo': {
      padding: 0,
    },
  },
  '&.expanded': {
    flexBasis: 228,
    minWidth: '228px',
  },

  [`& .${classes.subWrapper}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    padding: `12px ${theme.spacing(1.5)} 30px`,
    minHeight: '100%',
    minWidth: '256px',
    flex: '0 0 256px',
    boxSizing: 'border-box',
    zIndex: 10,
  },

  [`& .${classes.subNavItems}`]: {
    fontSize: 14,
    margin: theme.spacing(0.5, 0),
    '& a': {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(4.5),
      margin: 0,
    },
  },

  [`& .${classes.navWrapper}`]: {
    flex: '1 0 auto',

    '&.collapsed': {
      paddingLeft: 0,
      '& .Sidebar-menuItem, .Sidebar-dropdownTitleBox': {
        width: 36,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(0.5),
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: theme.spacing(0.5),
        '&:hover': {
          background: theme.palette.grey[600],
        },
      },

      '& .Sidebar-listItemIcon': {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },

  [`& .${classes.category}`]: {
    paddingLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    fontSize: '0.75rem',
    color: 'rgba(255, 255, 255, .5)',
  },

  [`& .${classes.menuItem}`]: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#FFF',
    minHeight: 36,
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    lineHeight: 1.38,
    paddingLeft: 5,
    position: 'relative',
    '&:hover': {
      background: theme.palette.grey[600],
      borderRadius: theme.spacing(0.5),
    },
    '&.small': {
      marginBottom: 0,
      '& span': {
        fontSize: '0.9rem',
      },
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: 14,
      color: theme.palette.primary.light,
    },
    '&.multiline': {
      '& .MuiListItemText-multiline': {
        paddingBottom: theme.spacing(0.5),
        '& .MuiListItemText-secondary': {
          color: theme.palette.secondary.light,
          lineHeight: 1,
          whiteSpace: 'normal',
          fontSize: 10,
        },
      },
    },
    '&.downloads-ready': {
      '& .MuiListItemIcon-root::after': {
        content: '""',
        position: 'absolute',
        top: 5,
        left: 25,
        width: 6,
        height: 6,
        borderRadius: theme.spacing(1),
        background: theme.palette.secondary.light,
      },
    },
  },

  [`& .${classes.activeMenuItem}`]: {
    pointerEvents: 'none',
    color: theme.palette.secondary.light,
    '& .MuiListItemText-primary': {
      color: theme.palette.secondary.light,
    },

    '& .MuiSvgIcon-root path': {
      fill: theme.palette.secondary.light,
    },
  },

  [`& .${classes.activeDropdown}`]: {
    '& .MuiListItemText-primary': {
      color: theme.palette.secondary.light,
    },

    '& .MuiSvgIcon-root path': {
      fill: theme.palette.secondary.light,
    },

    '&.collapsed': {
      '& .MuiSvgIcon-root path': {
        fill: theme.palette.secondary.light,
      },
    },
  },

  [`& .${classes.listItemIcon}`]: {
    minWidth: theme.spacing(4),
    color: '#FFF',
    '& svg': {
      fontSize: 18,
    },

    '&.active': {
      '& .MuiSvgIcon-root path': {
        fill: theme.palette.secondary.light,
      },
    },
  },

  [`& .${classes.account}`]: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '&.collapsed': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 0,
      gap: theme.spacing(0.5),
      justifyContent: 'start',
      '& .Sidebar-companyLogo': {
        marginRight: 0,
      },

      '& .Sidebar-listItemIcon': {
        width: '100%',
        justifyContent: 'center',
      },

      '& .Sidebar-menuItem': {
        width: 36,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        paddingLeft: 0,
        marginBottom: 0,
        '&:hover': {
          background: theme.palette.grey[600],
        },
      },
      '& .Sidebar-activeMenuItem': {
        '&:hover': {
          background: theme.palette.grey[600],
        },
      },

      '& .menu-wrapper': {
        display: 'flex',
        justifyContent: 'center',
      },

      '& .downloads-ready': {
        '& .MuiListItemIcon-root::after': {
          left: 28,
        },
      },

      '& .updates-available': {
        '& .MuiListItemIcon-root::after': {
          content: '""',
          position: 'absolute',
          top: 5,
          left: 25,
          width: 6,
          height: 6,
          borderRadius: theme.spacing(1),
          background: theme.palette.secondary.light,
        },
      },
    },
  },

  [`& .${classes.company}`]: {
    display: 'flex',
    alignItems: 'center',
    '&.collapsed': {
      width: 36,
      height: 36,
      display: 'flex',
      justifyContent: 'center',
    },
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '0 1 auto',
    marginBottom: theme.spacing(1.5),

    '& img': {
      paddingLeft: theme.spacing(0.5),
    },
  },

  [`& .${classes.companyLogo}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: 10,
    background: '#FFF',
    borderRadius: 34,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },

  [`& .${classes.companyName}`]: {
    color: '#FFF',
    maxWidth: 160,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.expandButton}`]: {
    border: 'none',
    borderRadius: '100px',
    background: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    '&:hover': {
      background: theme.palette.grey[600],
    },
  },

  [`& .${classes.flag}`]: {
    width: 20,
    marginRight: theme.spacing(2),
    '&.selected': {
      marginRight: 0,
      cursor: 'pointer',
    },
  },

  [`& .${classes.shortDisclaimer}`]: {
    color: theme.palette.grey[500],
    fontSize: '10px',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },

  [`& .${classes.hiddenText}`]: {
    display: 'none',
  },

  [`& .${classes.dropdownTitleBox}`]: {
    display: 'flex',
    color: theme.palette.primary.light,
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 36,
    padding: '0 5px',
    borderRadius: theme.spacing(0.5),

    '& .MuiListItemText-root span': {
      fontSize: 14,
    },

    '&:hover': {
      background: theme.palette.grey[600],
    },

    '& .dropdown-arrow': {
      width: 10,
      height: 6,
    },

    '& .opened': {
      transform: 'rotate(180deg)',
    },
  },

  [`& .${classes.dropdownItem}`]: {
    color: theme.palette.primary.light,
    minHeight: 'auto',
    '&.active': {
      color: theme.palette.secondary.light,
      pointerEvents: 'none',
    },
  },

  [`& .${classes.disclaimerIcon}`]: {
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.grey[600],
    },
  },
}));

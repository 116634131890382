import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationOutlined } from '../Icons';
import Typography from '@mui/material/Typography';
import { classes, StyledBox } from './styled';

type Props = {
  message: string;
};

const ErrorScreen = ({ message }: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <StyledBox>
      <ExclamationOutlined color="primary" />
      <Typography variant="body1" className={classes.errorBlock}>
        {t('Something went wrong')}.
        <br />
        {message ? (
          <>
            {t('Please provide the following details to technical support')}:
            <br />
            <span className={classes.message}>{message}</span>
          </>
        ) : (
          `${t('Please try again later')}.`
        )}
      </Typography>
    </StyledBox>
  );
};

export default ErrorScreen;

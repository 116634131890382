import { useEffect, useCallback, useReducer } from 'react';
import { getModels, patchModel } from '@api';
import { reducer } from '../screens/ModelsScreen/state';
import useGetToken from '@hooks/useGetToken';
import { Model } from '@models';
import { useUserProperties } from '@context/userProperties';

const useModelUpdate = () => {
  const { getToken } = useGetToken();
  const { setPreferences } = useUserProperties();
  const [{ loading, data, error, switching, sorting }, dispatch] = useReducer(reducer, {
    loading: false,
    data: [],
    error: '',
    switching: '',
    sorting: {
      key: 'created_at',
      dir: 'desc',
    },
  });

  const updateModel = useCallback(
    async (modelName: string) => {
      const model = data.find((dataModel) => dataModel.version === modelName) as Model;
      dispatch({ type: 'setSwitching', value: model.version });
      const token = await getToken();
      patchModel(token, model.version, model.default_scenarios)
        .then((data) => {
          setPreferences({ ...data, sector_classification: model.sector_classification });
          dispatch({ type: 'setSwitching', value: '' });
          localStorage.removeItem('newModelVersion');
        })
        .catch((error) => {
          dispatch({ type: 'setSwitching', value: '' });
          dispatch({ type: 'setError', value: error?.data?.error_message });
        });
    },
    [data, getToken, setPreferences],
  );

  useEffect(() => {
    (async () => {
      const token = await getToken();
      dispatch({ type: 'setLoading', value: true });
      getModels(token)
        .then((data) => {
          dispatch({ type: 'dataFetched', data });
        })
        .catch((err) => dispatch({ type: 'setError', value: err?.data?.error_message }))
        .finally(() => dispatch({ type: 'setLoading', value: false }));
    })();
  }, [getToken]);

  return {
    loading,
    updateModel,
    error,
    data,
    switching,
    sorting,
    dispatch,
  };
};

export { useModelUpdate };

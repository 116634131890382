import { createContext, useReducer, useContext, Dispatch } from 'react';
import { AlertColor } from '@mui/material';

import {
  DATA_CLEARED,
  DELETE_HOLDING,
  SET_UPDATING_HOLDING,
  UPDATE_HOLDING,
  ADD_HOLDING,
  SET_ERROR,
  SET_FILTER_BY,
  ON_UPLOAD,
  SET_UPLOADING_MODE,
  CLOSE_ALERT,
  SET_EDITABLE_ROW_INDEX,
  SET_CORPORATE_TYPE,
  SET_DOWNLOAD_REQUESTED,
} from './actions';

export type State = {
  error: string;
  filterBy: string;
  uploadingMode: boolean;
  alert: {
    severity: AlertColor;
    text: string;
  };
  updatingHolding?: {
    rowIndex: number;
    dataKey: string;
  };
  editableRowIndex: number;
  addedHolding: number;
  corporateType: {
    corporate_mod: string;
    corporate: string;
  };
  refetchFlag: boolean;
  downloadRequested: boolean;
};

type Action =
  | { type: typeof DATA_CLEARED }
  | { type: typeof DELETE_HOLDING; index: number; resetFilterBy: boolean }
  | { type: typeof SET_UPDATING_HOLDING; rowIndex: number; dataKey: string }
  | { type: typeof UPDATE_HOLDING; index: number; resetFilterBy: boolean }
  | { type: typeof ADD_HOLDING }
  | { type: typeof SET_ERROR; value: string }
  | { type: typeof SET_FILTER_BY; value: string }
  | { type: typeof ON_UPLOAD }
  | {
      type: typeof SET_UPLOADING_MODE;
      value: boolean;
      corporateType?: string;
      assetType?: string;
      refetchFlag?: boolean;
    }
  | { type: typeof CLOSE_ALERT }
  | { type: typeof SET_EDITABLE_ROW_INDEX; index: number }
  | {
      type: typeof SET_CORPORATE_TYPE;
      corporateType: string;
      assetType: string;
      refetchFlag: boolean;
    }
  | { type: typeof SET_DOWNLOAD_REQUESTED; value: boolean };

const initialState = {
  error: '',
  filterBy: '',
  uploadingMode: false,
  alert: {
    severity: 'success' as AlertColor,
    text: '',
  },
  updatingHolding: undefined,
  editableRowIndex: -1,
  addedHolding: 0,
  corporateType: {
    corporate_mod: 'corporate_mod',
    corporate: 'entities',
  },
  refetchFlag: false,
  downloadRequested: false,
};

const HoldingsReducer = createContext<{
  state: State;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case DATA_CLEARED: {
      return {
        ...state,
        filterBy: '',
        updatingHolding: undefined,
      };
    }
    case DELETE_HOLDING: {
      return {
        ...state,
        editableRowIndex: -1,
        ...(state.updatingHolding?.rowIndex === action.index && {
          updatingHolding: undefined,
        }),
        ...(action.resetFilterBy && {
          filterBy: '',
        }),
      };
    }
    case SET_UPDATING_HOLDING: {
      return {
        ...state,
        updatingHolding: {
          rowIndex: action.rowIndex,
          dataKey: action.dataKey,
        },
      };
    }
    case UPDATE_HOLDING: {
      return {
        ...state,
        updatingHolding: undefined,
        editableRowIndex: -1,
        ...(action.resetFilterBy && {
          filterBy: '',
        }),
      };
    }
    case ADD_HOLDING: {
      return {
        ...state,
        addedHolding: state.addedHolding + 1,
        alert: {
          severity: 'success',
          text: 'Holding added',
        },
      };
    }
    case SET_ERROR:
      return {
        ...state,
        error: action.value,
      };
    case SET_FILTER_BY:
      return {
        ...state,
        filterBy: action.value,
      };
    case ON_UPLOAD:
      return {
        ...state,
        filterBy: '',
        uploadingMode: false,
        updatingHolding: undefined,
        editableRowIndex: -1,
      };
    case SET_UPLOADING_MODE:
      return {
        ...state,
        uploadingMode: action.value,
        refetchFlag: Boolean(action.refetchFlag),
        corporateType:
          {
            ...state.corporateType,
            [action.assetType as string]: action.corporateType,
          } || state.corporateType,
      };
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          text: '',
        },
      };
    case SET_EDITABLE_ROW_INDEX:
      return {
        ...state,
        editableRowIndex: action.index,
      };
    case SET_CORPORATE_TYPE:
      return {
        ...state,
        corporateType: {
          ...state.corporateType,
          [action.assetType]: action.corporateType,
        },
        refetchFlag: action.refetchFlag,
        ...(action.assetType === 'corporate_mod' && { uploadingMode: false }),
      };
    case SET_DOWNLOAD_REQUESTED:
      return {
        ...state,
        downloadRequested: action.value,
      };
    default:
      return state;
  }
};

const HoldingsProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <HoldingsReducer.Provider value={{ state, dispatch }}>{children}</HoldingsReducer.Provider>
  );
};

const useHoldingsContext = () => {
  return useContext(HoldingsReducer);
};

export { HoldingsProvider, useHoldingsContext };

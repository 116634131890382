import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material';

const NavTooltip = styled(({ className, disableHoverListener = false, ...props }: TooltipProps) => (
  <Tooltip {...props} disableHoverListener={disableHoverListener} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.dark,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'auto',
    fontSize: theme.typography.pxToRem(14),
    background: theme.palette.primary.dark,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    marginLeft: '22px !important',
    fontWeight: 400,
    '& .Sidebar-subNavItems': {
      display: 'flex',
      flexDirection: 'column',
      '& a': {
        padding: '6px',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '16px',
        '&:hover': {
          background: theme.palette.grey[600],
          borderRadius: '4px',
        },
      },
      '& .active': {
        color: theme.palette.secondary.light,
        pointerEvents: 'none',
      },
    },
    '& .disclaimerBox': {
      padding: '0 4px',
    },

    '& .menu-tooltip': {
      minWidth: 90,
      '& p': {
        padding: 6,
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.grey[600],
          borderRadius: '4px',
        },
      },
      '& .active': {
        color: theme.palette.secondary.light,
        pointerEvents: 'none',
      },
    },
  },
}));

export { NavTooltip };

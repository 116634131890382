import { useDownloadsNotification } from '@context/downloadsNotification';
import { useUserProperties } from '@context/userProperties';
import { useTranslation } from 'react-i18next';
import {
  HomeIcon,
  GlobeIcon,
  CompassIcon,
  CollectionsIcon,
  ModelsIcon,
  BookmarkIcon,
  ExportsIcon,
  AnalysisIcon,
  SecurityIcon,
  CustomAssetIcon,
} from '../components/Icons';
import { useCallback } from 'react';

const useSidebarItems = () => {
  const {
    preferences,
    appConfig: { securityRoutes, customAssetRoutes, extraRoutes, portfolioConfig },
  } = useUserProperties();
  const { downloadsAvailable } = useDownloadsNotification();
  const { t } = useTranslation(['sidemenu', 'disclaimer']);
  const updateAvailable =
    preferences.newest_available_model_version &&
    preferences.model_version !== preferences.newest_available_model_version;

  const getRouteLinks = useCallback(
    (routesGroup) => [
      ...routesGroup
        .sort((a, b) => a.order - b.order)
        .map((route) => ({
          title: route.title,
          to: route.path,
        })),
    ],
    [],
  );

  const menuItems: any[] = [
    { title: t('Home'), icon: <HomeIcon />, to: '/' },
    ...(extraRoutes.includes('scenarios')
      ? [{ title: t('Climate scenarios'), icon: <GlobeIcon />, to: '/scenarios' }]
      : []),
    ...(extraRoutes.includes('location-explorer')
      ? [{ title: t('Location explorer'), icon: <CompassIcon />, to: '/location-explorer' }]
      : []),
    ...(customAssetRoutes?.length
      ? [
          {
            title: t('Custom assets'),
            icon: <CustomAssetIcon />,
            isDropdown: true,
            links: getRouteLinks(customAssetRoutes),
          },
        ]
      : []),
    ...(securityRoutes?.length
      ? [
          {
            title: t('Securities'),
            icon: <SecurityIcon />,
            isDropdown: true,
            links: getRouteLinks(securityRoutes),
          },
        ]
      : []),
    ...(portfolioConfig.assetTypes.length
      ? [
          {
            title: t('Your portfolio'),
            icon: <CollectionsIcon />,
            to: '/portfolio',
          },
        ]
      : []),
    { title: t('Portfolio analysis'), icon: <AnalysisIcon />, to: '/analysis' },
  ];

  const footerLinks: any[] = [
    { title: t('Bookmarks'), icon: <BookmarkIcon />, to: '/bookmarks' },
    ...(extraRoutes.includes('exports')
      ? [
          {
            title: t('Downloads'),
            icon: <ExportsIcon />,
            to: '/exports',
            extraClassName: downloadsAvailable ? 'downloads-ready' : '',
          },
        ]
      : []),
    {
      title: `${t('Model version')}: ${preferences.model_version}`,
      icon: <ModelsIcon />,
      to: '/models',
      newUpdates: updateAvailable ? t('updateAvailable') : '',
      extraClassName: updateAvailable ? 'updates-available' : '',
    },
  ];

  return { menuItems, footerLinks, updateAvailable };
};

export default useSidebarItems;

import { useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { CollapseIcon, ExpandIcon } from '../Icons';
import NavigationTabs from './components/NavigationTabs';
import { classes, StyledBox } from './styled';
import logo from '@assets/brand-logo.svg';
import logoSmall from '@assets/logo-small.svg';
import { useUserProperties } from '@context/userProperties';
import { useSidebarState } from '@context/sidebarState';

const Sidebar = () => {
  const theme = useTheme();
  const { userInfo } = useUserProperties();
  const { expandedSidebar, setExpandedSidebar } = useSidebarState();

  useLayoutEffect(() => {
    const {
      breakpoints: {
        values: { lg },
      },
    } = theme;
    const isDesktop = window.innerWidth >= lg;

    if (isDesktop) {
      setExpandedSidebar(true);
    }
  }, [setExpandedSidebar, theme]);

  return (
    <StyledBox className={clsx(expandedSidebar ? 'expanded' : 'collapsed')}>
      <Box className={classes.header}>
        <IconButton
          className={classes.expandButton}
          data-testid="expand-button"
          onClick={(e) => {
            e.stopPropagation();
            setExpandedSidebar(!expandedSidebar);
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 200);
          }}
        >
          {expandedSidebar ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
        <img
          className={classes.logo}
          src={expandedSidebar ? logo : logoSmall}
          alt="Planetrics logo"
        />
      </Box>
      <NavigationTabs userInfo={userInfo} expanded={expandedSidebar} />
    </StyledBox>
  );
};

export default Sidebar;

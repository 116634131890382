import { NavTooltip } from './NavTooltip';
import clsx from 'clsx';
import { classes } from '../styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DisclaimerIcon } from '../../Icons';
import { partial } from 'lodash';
import { useTranslation } from 'react-i18next';

type DisclaimerProps = {
  expanded: boolean;
  autoHideTitles: string;
  setMenuOpened: (value: boolean) => void;
};

export const NavDisclaimer = ({ expanded, autoHideTitles, setMenuOpened }: DisclaimerProps) => {
  const { t } = useTranslation(['disclaimer']);
  const disclaimerTitle = t('disclaimer title');
  const disclaimerContent = t('disclaimer short');

  return expanded ? (
    <Typography variant="body2" className={clsx(classes.shortDisclaimer, autoHideTitles)}>
      {disclaimerContent}
    </Typography>
  ) : (
    <NavTooltip
      placement="right-end"
      disableHoverListener={expanded}
      onOpen={partial(setMenuOpened, false)}
      title={
        <Box className="disclaimerBox">
          <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{disclaimerTitle}</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 12 }}>{disclaimerContent}</Typography>
        </Box>
      }
    >
      <Box className={classes.disclaimerIcon}>
        <DisclaimerIcon />
      </Box>
    </NavTooltip>
  );
};

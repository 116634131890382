import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Dialog } from '../Dialog';

const PREFIX = 'Wrapper';

export const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  wrapper: `${PREFIX}-wrapper`,
  dialog: `${PREFIX}-dialog`,
  dialogContent: `${PREFIX}-dialogContent`,
};

export const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '.printing .no-print': {
    display: 'none',
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    backdropFilter: `blur(${theme.spacing(4)})`,
  },

  '&.none-blurred': {
    '& .MuiDialog-container': {
      zIndex: 9,
      backdropFilter: 'blur(0)',
    },
  },

  '& .buttonBox': {
    display: 'flex',
    marginBottom: 0,
    '& button': {
      '&:first-of-type': {
        marginRight: theme.spacing(2),
      },
    },
  },

  [`& .${classes.dialog}`]: {
    padding: theme.spacing(2),
    borderRadius: 12,
    width: 700,
    height: 400,
    '& button': {
      flex: '0 0 34px',
      width: 200,
      maxWidth: 400,
    },
  },

  [`& .${classes.dialogContent}`]: {
    padding: 0,
    margin: theme.spacing(2, 0),
    '& p': {
      marginTop: theme.spacing(4),
      flex: '0 0 auto',
      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },

  '& .small-width': {
    maxWidth: 380,
  },

  '& .noMargin': {
    marginBottom: '0 !important',
  },

  '& .checkbox-container': {
    margin: theme.spacing(1, 0, 2, 0),
  },

  '& .splited-text': {
    margin: '0 !important',
  },

  '&.route-message-modal': {
    '& .MuiDialog-paper': {
      width: 380,
    },
    '& .MuiCheckbox-root': {
      '&:hover': {
        background: 'transparent',
      },
      '&.Mui-checked': {
        color: theme.palette.primary.blue,
      },
    },
  },
}));

export const TopBar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3, 1.5, 3),
  paddingTop: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  color: theme.palette.primary.black,
  background: '#fff',
  marginBottom: 0,
  '& h6': {
    color: theme.palette.primary.dark,
    fontSize: 12,
    marginBottom: theme.spacing(3.5),
    fontWeight: 400,
    paddingLeft: theme.spacing(0.5),
  },
  '& h5': {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: -1,
  },
}));

export const FilterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.75, 2),
  borderRadius: theme.spacing(1),
  background: theme.palette.primary.light,
}));

export const ContentPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: '1 1 auto',
  borderRadius: 27,
  boxSizing: 'border-box',
  boxShadow: '0 3px 33px 0 rgba(137, 156, 197, 0.39)',
}));

export const ContentGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  flex: '1 1 auto',
  boxSizing: 'border-box',
}));

export const ScrollableBox = styled(Box)(() => ({
  height: '100%',
  overflow: 'auto',
}));

import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { getPendingFunds } from '@api';
import useGetToken from '@hooks/useGetToken';
import useInterval from '@hooks/useInterval';
import { PendingFunds } from '@models';

type ContextType = {
  pendingFunds: PendingFunds;
  polling: boolean;
  checkStatus: () => void;
  onCancelProcessing: (type: string) => any;
};

const PendingFundsContext = createContext<ContextType>({
  pendingFunds: {
    normal: [],
    benchmark: [],
  },
  polling: false,
  checkStatus: () => undefined,
  onCancelProcessing: () => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const PendingFundsProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const [pendingFunds, setPendingFunds] = useState<PendingFunds>({
    normal: [],
    benchmark: [],
  });
  const [polling, setPolling] = useState<boolean>(false);
  const checkStatus = useCallback(async () => {
    const token = await getToken();
    Promise.allSettled([
      getPendingFunds(token, 'normal', {}),
      getPendingFunds(token, 'benchmark', {}),
    ]).then(([normalFund, benchmarkFund]) => {
      const data: PendingFunds = {
        normal: normalFund.status === 'fulfilled' ? normalFund.value : [],
        benchmark: benchmarkFund.status === 'fulfilled' ? benchmarkFund.value : [],
      };
      setPendingFunds(data);
      setPolling(data.normal.length + data.benchmark.length > 0);
    });
  }, [getToken]);

  const onCancelProcessing = useCallback(
    async (type: string) => {
      setPendingFunds({ ...pendingFunds, [type.toLowerCase()]: [] });
      if (pendingFunds.normal.length + pendingFunds.benchmark.length === 0) {
        setPolling(false);
      }
    },
    [pendingFunds],
  );

  useInterval(
    () => {
      checkStatus();
    },
    polling ? 10000 : null,
  );

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  return (
    <PendingFundsContext.Provider
      value={{ pendingFunds, polling, checkStatus, onCancelProcessing }}
    >
      {children}
    </PendingFundsContext.Provider>
  );
};

export const usePendingFunds = () => useContext(PendingFundsContext);

import axios, { AxiosRequestConfig } from 'axios';
import { JsonDecoder } from 'ts.data.json';
import FileDownload from 'js-file-download';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';
import {
  fundDecoder,
  fundsResponseDecoder,
  regionsResponseDecoder,
  countriesResponseDecoder,
  scenariosResponseDecoder,
  sectorsResponseDecoder,
  // analysisResponseDecoder,
  modelDecoder,
  modelsResponseDecoder,
  securityRouteModesDecoder,
  securityRouteLayoutDecoder,
  securityRouteLayoutsDecoder,
  settingsDecoder,
  savedFilterDecoder,
  savedFiltersDecoder,
  bulkFileInfoDecoder,
  bulkFileInfosDecoder,
  isinHoldingsDecoder,
  realEstateHoldingsDecoder,
  realAssetHoldingsDecoder,
  privateEquityHoldingsDecoder,
  corporateEntitiesDecoder,
  fundImportPreviewDecoder,
  userInfoDecoder,
  entityHoldingsDecoder,
  isinEntitiesResponseDecoder,
  isinEntityDecoder,
  metricsDecoder,
  indicatorDataDecoder,
  apiKeyDecoder,
  apiKeysDecoder,
  apiKeyPostDecoder,
  bulkfundPreviewDecoder,
  realAssetEntityDecoder,
  realAssetEntitiesResponseDecoder,
  mapboxStateDecoder,
  corporateActivitiesDecoder,
  usersResponseDecoder,
  fundOptionsDecoder,
  fundOptionsResponseDecoder,
  MODHoldingsDecoder,
  scenarioExtraParamsDecoder,
  corporatesModResponseDecoder,
  corporatesModDecoder,
  corporateModSectorsDecoder,
  corporateModRegionsDecoder,
  creditRatingsResponseDecoder,
  companyCharacteristicsResponseDecoder,
  modSectorsRegionsPreviewDecoder,
  userPropertiesDecoder,
  corporateModEmissionTargetsDecoder,
  corporateModRevenueTargetsDecoder,
} from './decoders';

import {
  Params,
  FundOption,
  Fund,
  FundsResponse,
  FundsPostRequestData,
  Region,
  Country,
  Scenario,
  Sector,
  Model,
  SecurityRouteMode,
  Preferences,
  SavedFilter,
  SavedFilterPostData,
  Holdings,
  UserInfo,
  IsinEntity,
  IsinEntityResponse,
  EntityHoldingsResponse,
  BulkFileInfo,
  BulkFileParams,
  Metric,
  Coordinates,
  SecurityRouteLayout,
  ApiKey,
  ApiKeyPost,
  HoldingEntity,
  RealAssetEntity,
  RealAssetEntityResponse,
  MapboxState,
  SavedWarmingLevel,
  UsersResponse,
  FundOptionsResponse,
  ScenarioExtraParam,
  CorporateMODEntity,
  CorporateMODEntityResponse,
  SettingsFilters,
  CreditRating,
  CompanyCharacteristics,
  UserProperties,
  LocationExplorerMetrics,
} from './models';
import { VirtualSelectRowsLimit, InfiniteTableRowsLimit, FundsLimit } from './constants';
import { generateFileName } from '@utils';

/* eslint-disable prefer-arrow/prefer-arrow-functions */

async function decodeResponse<T>(
  decoder: JsonDecoder.Decoder<T>,
  responseData: any,
  reject: (reason?: any) => void,
): Promise<T> {
  return decoder.decodeToPromise(responseData).catch((err) => {
    if (process.env.REACT_APP_API_ROOT === 'http://localhost:8080') {
      /* eslint-disable-next-line no-console*/
      console.log(err);
    }
    Sentry.captureException(new TypeError(err));
    reject({ data: { error_message: 'Error retrieving data. Please try again later.' } });
    throw err;
  });
}

export const stringifyParams = (params: Params): string =>
  Object.keys(params)
    .map((key) => {
      // @ts-ignore
      const value = Array.isArray(params[key]) ? params[key].join(',') : params[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`;
    })
    .join('&');

const instance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_API_ROOT}${
    process.env.REACT_APP_API_VERSION ? '/v' + process.env.REACT_APP_API_VERSION + '/' : ''
  }`,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
});

instance.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = i18next.language;
  return config;
});

instance.interceptors.response.use(
  (response: any) => response,
  (error) => {
    if (error.statusCode === '550') {
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  },
);

const api = (token: string) => {
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance;
};

export async function getFunds(
  token: string,
  fundType: string,
  { limit = FundsLimit, ...rest }: Params,
): Promise<FundsResponse> {
  const stringParams = stringifyParams({ ...{}, limit, ...rest });
  return new Promise<FundsResponse>((resolve, reject) => {
    api(token)
      .get(`/funds/${fundType}/?${stringParams}`)
      .then((response) =>
        decodeResponse(fundsResponseDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getFundOptions(token: string, params: Params): Promise<FundOption[]> {
  const stringParams = stringifyParams({ fund_type: 'Normal', dropdown: true, ...params });
  return new Promise<FundOption[]>((resolve, reject) => {
    api(token)
      .get(`/funds/?${stringParams}`)
      .then((response) =>
        decodeResponse(fundOptionsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function getFundOptionsWithPagination(
  token: string,
  fundType: string,
  { limit = FundsLimit, ...rest }: Params,
  signal?: AbortSignal,
): Promise<FundOptionsResponse> {
  const stringParams = stringifyParams({ ...{}, limit, dropdown: true, ...rest });
  return new Promise<FundOptionsResponse>((resolve, reject) => {
    api(token)
      .get(`/funds/${fundType}/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(fundOptionsResponseDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function postFunds(
  token: string,
  fundType: string,
  data: FundsPostRequestData,
): Promise<Fund> {
  return new Promise<Fund>((resolve, reject) => {
    api(token)
      .post(`/funds/${fundType}/`, data)
      .then((response) =>
        decodeResponse(fundDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function postFundsPreview(token: string, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post('/funds/bulk-preview/', data)
      .then((response) =>
        decodeResponse(bulkfundPreviewDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error?.response?.data));
  });
}

export async function postBulkFunds(
  token: string,
  data: FormData,
  visibility: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/bulk-upload/?normal_funds_visibility=${visibility}`, data)
      .then((response) => resolve(response.data))
      .catch(reject);
  });
}

export async function getFund(token: string, id: number, params: Params): Promise<Fund> {
  return new Promise<Fund>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/funds/${id}/?${stringParams}`)
      .then((response) =>
        decodeResponse(fundDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function generateModEstimates(token: string, id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/holdings/trigger-estimates-generation/`)
      .then(resolve)
      .catch((err) => reject(err.response));
  });
}

export async function putFund(token: string, data: Fund): Promise<Fund> {
  return new Promise<Fund>((resolve, reject) => {
    api(token)
      .put(`/funds/${data.id}/`, data)
      .then((response) =>
        decodeResponse(fundDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response.data));
  });
}

export async function deleteFund(token: string, fundType: string, id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${fundType}/${id}/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function deleteMultipleFunds(
  token: string,
  fundType: string,
  data: { selected: number[] },
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${fundType}/bulk-delete/`, { data })
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function deleteMultipleHoldings(
  token: string,
  fundId: number,
  assetType: string,
  data: { selected: number[] },
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${fundId}/${assetType}/holdings/bulk-delete/`, { data })
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function getPendingFunds(
  token: string,
  fundType: string,
  params: Params,
): Promise<number[]> {
  return new Promise<number[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/funds/${fundType}/pending-list/?${stringParams}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err.response));
  });
}

export async function cancelProcessingPendingFunds(token: string, fundType: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api(token)
      .patch(`/funds/${fundType}/cancel-pending/`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err.response));
  });
}

export async function getModSectors(
  token: string,
  id: number,
  params: Params,
  config?: AxiosRequestConfig,
): Promise<Holdings> {
  const stringParams = stringifyParams({ ...{}, ...params });
  return new Promise<Holdings>((resolve, reject) => {
    api(token)
      .get(`/funds/${id}/corporate_mod/sectors/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        ...config,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, 'sectors'));
        } else {
          return decodeResponse(corporateModSectorsDecoder, response.data, reject)
            .then(resolve)
            .catch(reject);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getModRegions(
  token: string,
  id: number,
  params: Params,
  config?: AxiosRequestConfig,
): Promise<Holdings> {
  const stringParams = stringifyParams({ ...{}, ...params });
  return new Promise<Holdings>((resolve, reject) => {
    api(token)
      .get(`/funds/${id}/corporate_mod/regions/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        ...config,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, 'regions'));
        } else {
          return decodeResponse(corporateModRegionsDecoder, response.data, reject)
            .then(resolve)
            .catch(reject);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getModEmissionTargets(
  token: string,
  id: number,
  params: Params,
  config?: AxiosRequestConfig,
): Promise<Holdings> {
  const stringParams = stringifyParams({ ...{}, ...params });
  return new Promise<Holdings>((resolve, reject) => {
    api(token)
      .get(`/funds/${id}/corporate_mod/emissions_targets/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        ...config,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, 'emissions_targets'));
        } else {
          return decodeResponse(corporateModEmissionTargetsDecoder, response.data, reject)
            .then(resolve)
            .catch(reject);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getModRevenueTargets(
  token: string,
  id: number,
  params: Params,
  config?: AxiosRequestConfig,
): Promise<Holdings> {
  const stringParams = stringifyParams({ ...{}, ...params });
  return new Promise<Holdings>((resolve, reject) => {
    api(token)
      .get(`/funds/${id}/corporate_mod/revenue_share_targets/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        ...config,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, 'revenue_share_targets'));
        } else {
          return decodeResponse(corporateModRevenueTargetsDecoder, response.data, reject)
            .then(resolve)
            .catch(reject);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getParticularModAssetType(
  token: string,
  fundId: number,
  corporateType: string,
  params: Params,
  config?: AxiosRequestConfig,
): Promise<any> {
  const stringParams = stringifyParams({ ...{}, ...params });
  return new Promise<any>((resolve, reject) => {
    api(token)
      .get(`/funds/${fundId}/corporate_mod/${corporateType}/?${stringParams}`, { ...config })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(
            response.data,
            generateFileName(response.headers, `corporate_mod_${corporateType}`),
          );
        } else {
          resolve(response.data);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getHoldings(
  token: string,
  id: number,
  assetType: string,
  { limit = InfiniteTableRowsLimit, ...rest }: Params,
  config?: AxiosRequestConfig,
): Promise<Holdings> {
  const stringParams = stringifyParams({ ...{}, limit, ...rest });

  return new Promise<Holdings>((resolve, reject) => {
    api(token)
      .get(`/funds/${id}/${assetType}/holdings/?${stringParams}`, { ...config })
      .then((response) => {
        switch (assetType) {
          case 'listed':
            return decodeResponse(isinHoldingsDecoder, response.data, reject)
              .then(resolve)
              .catch(reject);
          case 'real_estate':
            return decodeResponse(realEstateHoldingsDecoder, response.data, reject)
              .then(resolve)
              .catch(reject);
          case 'private_equity':
            return decodeResponse(privateEquityHoldingsDecoder, response.data, reject)
              .then(resolve)
              .catch(reject);
          case 'corporate':
            return decodeResponse(corporateEntitiesDecoder, response.data, reject)
              .then(resolve)
              .catch(reject);
          case 'corporate_mod':
            return decodeResponse(MODHoldingsDecoder, response.data, reject)
              .then(resolve)
              .catch(reject);
          case 'real_asset':
            return decodeResponse(realAssetHoldingsDecoder, response.data, reject)
              .then(resolve)
              .catch(reject);
          default:
            return undefined;
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function downloadHoldings(
  token: string,
  fundId: number,
  assetType: string,
  data: { selected: number[] },
  params: Params,
) {
  const stringParams = stringifyParams(params);
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${fundId}/${assetType}/holdings/bulk-download/?${stringParams}`, data, {
        responseType: 'blob',
      })
      .then((response) => {
        FileDownload(
          response.data,
          generateFileName(
            response.headers,
            `${assetType === 'corporate' ? 'entities' : assetType}`,
          ),
        );
        resolve(response.data);
      })
      .catch((error) => reject(error.response));
  });
}

export async function postHoldings(
  token: string,
  id: number,
  assetType: string,
  data: FormData,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/${assetType}/holdings/upload/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}

export async function postHoldingsPreview(
  token: string,
  id: number,
  assetType: string,
  data: FormData,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/${assetType}/holdings/preview/`, data)
      .then((response) =>
        decodeResponse(modSectorsRegionsPreviewDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((error) => reject(error.message));
  });
}

export async function deleteHoldings(
  token: string,
  id: number,
  assetType: string,
  corporateType?: string,
): Promise<any> {
  const particularSubAsset = corporateType ? `${corporateType}/` : '';
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${id}/${assetType}/${particularSubAsset}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function deleteActivities(token: string, fundId: number): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${fundId}/activities/bulk-delete/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function deleteHolding(
  token: string,
  id: number,
  assetType: string,
  holdingId: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${id}/${assetType}/holdings/${holdingId}/`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function deleteOptionalModData(
  token: string,
  id: number,
  assetType: string,
  holdingId: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/funds/${id}/corporate_mod/${assetType}/?holding_id=${holdingId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function postHolding(
  token: string,
  id: number,
  assetType: string,
  data: Partial<HoldingEntity>,
): Promise<HoldingEntity> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/${assetType}/holdings/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function patchHolding(
  token: string,
  id: number,
  assetType: string,
  holdingId: number,
  data: Partial<HoldingEntity>,
  params: Params,
): Promise<HoldingEntity> {
  const stringParams = stringifyParams(params);
  return new Promise((resolve, reject) => {
    api(token)
      .patch(`/funds/${id}/${assetType}/holdings/${holdingId}/?${stringParams}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function getGeography(token: string, params: Params): Promise<Region[]> {
  return new Promise<Region[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/geographies/?${stringParams}`)
      .then((response) =>
        decodeResponse(regionsResponseDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getCountries(
  token: string,
  params: Params,
  signal?: AbortSignal,
): Promise<Country[]> {
  return new Promise<Country[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/countries/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(countriesResponseDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getScenarios(token: string, params: Params): Promise<Scenario[]> {
  return new Promise<Scenario[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/scenarios/?${stringParams}`)
      .then((response) =>
        decodeResponse(scenariosResponseDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getScenarioExtraParamOptions(
  token: string,
  mode: string,
  params: Params,
): Promise<ScenarioExtraParam[]> {
  return new Promise<ScenarioExtraParam[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/scenario_data/${mode}/options/?${stringParams}`)
      .then((response) =>
        decodeResponse(scenarioExtraParamsDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getSectors(
  token: string,
  params: Params,
  signal?: AbortSignal,
): Promise<Sector[]> {
  return new Promise<Sector[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/sectors/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(sectorsResponseDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getCreditRatingOptions(token: string): Promise<CreditRating[]> {
  return new Promise<CreditRating[]>((resolve, reject) => {
    api(token)
      .get('/credit_ratings/')
      .then((response) =>
        decodeResponse(creditRatingsResponseDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getAnalysis(
  token: string,
  mode: string,
  params: Params,
  config?: AxiosRequestConfig,
  dataPointsParams?: string,
): Promise<any> {
  const stringParams = stringifyParams(params);
  const pointsParams = dataPointsParams ? `&${dataPointsParams}` : '';
  return new Promise((resolve, reject) => {
    api(token)
      .get(`/portfolio/${mode}/?${stringParams}${pointsParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        ...config,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, mode));
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error.response || error.message) {
          return reject(error.response);
        }
      });
  });
}

export async function getEquityAnalysis(
  token: string,
  id: string,
  sectorId: string,
  countryCode: string,
  assetClass: string,
  mode: string,
  params: Params = {},
  signal?: AbortSignal,
): Promise<any> {
  const stringParams = stringifyParams({ ...params });
  const url =
    assetClass === 'private_equity'
      ? `/entity_analysis/${assetClass}/${sectorId}/${countryCode}/${mode}/?${stringParams}`
      : `/entity_analysis/${assetClass}/${id}/${mode}/?${stringParams}`;
  return new Promise((resolve, reject) => {
    api(token)
      .get(url, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        signal,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, mode));
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => reject(error.response));
  });
}

export async function getTooltip(token: string, mode: string, params: Params): Promise<any> {
  const stringParams = stringifyParams(params);
  return new Promise((resolve, reject) => {
    api(token)
      .get(`/tooltip/${mode}/?${stringParams}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response));
  });
}

export async function getIsinEntities(
  token: string,
  assetClass: string,
  params: Params,
  signal?: AbortSignal,
): Promise<IsinEntityResponse> {
  const stringParams = stringifyParams({ limit: VirtualSelectRowsLimit, ...params });
  return new Promise<IsinEntityResponse>((resolve, reject) => {
    api(token)
      .get(`/universe/${assetClass}/?${stringParams}`, { signal })
      .then((response) => {
        if (assetClass === 'corporate') {
          resolve(response.data);
        } else {
          decodeResponse(isinEntitiesResponseDecoder, response.data, reject)
            .then(resolve)
            .catch(reject);
        }
      });
  });
}

export async function getIsinEntity(
  token: string,
  assetClass: string,
  id: string,
  params: Params,
  signal?: AbortSignal,
): Promise<IsinEntity> {
  const stringParams = stringifyParams(params);
  return new Promise<IsinEntity>((resolve, reject) => {
    api(token)
      .get(`/universe/${assetClass}/${id}/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(isinEntityDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getRealAssetEntities(
  token: string,
  assetClass: string,
  params: Params,
  signal?: AbortSignal,
): Promise<RealAssetEntityResponse> {
  const stringParams = stringifyParams({ limit: VirtualSelectRowsLimit, ...params });
  return new Promise<RealAssetEntityResponse>((resolve, reject) => {
    api(token)
      .get(`/universe/${assetClass}/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(realAssetEntitiesResponseDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      );
  });
}

export async function getRealAssetEntity(
  token: string,
  assetClass: string,
  id: string,
  params: Params,
  signal?: AbortSignal,
): Promise<RealAssetEntity> {
  const stringParams = stringifyParams(params);
  return new Promise<RealAssetEntity>((resolve, reject) => {
    api(token)
      .get(`/universe/${assetClass}/${id}/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(realAssetEntityDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getCorporatedModEntities(
  token: string,
  assetClass: string,
  params: Params,
  signal?: AbortSignal,
): Promise<CorporateMODEntityResponse> {
  const stringParams = stringifyParams({ limit: VirtualSelectRowsLimit, ...params });
  return new Promise<CorporateMODEntityResponse>((resolve, reject) => {
    api(token)
      .get(`/universe/${assetClass}/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(corporatesModResponseDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      );
  });
}

export async function getCorporateModEntity(
  token: string,
  assetClass: string,
  id: string,
  params: Params,
  signal?: AbortSignal,
): Promise<CorporateMODEntity> {
  const stringParams = stringifyParams(params);
  return new Promise<CorporateMODEntity>((resolve, reject) => {
    api(token)
      .get(`/universe/${assetClass}/${id}/?${stringParams}`, { signal })
      .then((response) =>
        decodeResponse(corporatesModDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getEntitiesPortfolioMatch(
  token: string,
  mode: string,
  params: Params,
  config?: AxiosRequestConfig,
): Promise<EntityHoldingsResponse> {
  const stringParams = stringifyParams({
    ...(params.format !== 'csv' && { limit: InfiniteTableRowsLimit }),
    ...params,
  });
  return new Promise<EntityHoldingsResponse>((resolve, reject) => {
    api(token)
      .get(`/portfolio/${mode}/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
        ...config,
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, mode));
        } else {
          decodeResponse(entityHoldingsDecoder, response.data, reject).then(resolve).catch(reject);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getModels(token: string): Promise<Model[]> {
  return new Promise<Model[]>((resolve, reject) => {
    api(token)
      .get(`/model_versions/`)
      .then((response) =>
        decodeResponse(modelsResponseDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getModel(token: string, id: string): Promise<Model> {
  return new Promise<Model>((resolve, reject) => {
    api(token)
      .get(`/model_versions/${id}`)
      .then((response) =>
        decodeResponse(modelDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getUserProperties(token: string): Promise<UserProperties> {
  return new Promise<UserProperties>((resolve, reject) => {
    api(token)
      .get(`/user_properties/`)
      .then((response) =>
        decodeResponse(userPropertiesDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getSecurityRouteModes(
  token: string,
  assetClass: string,
  params: Params,
): Promise<SecurityRouteMode[]> {
  const stringParams = stringifyParams(params);
  return new Promise<SecurityRouteMode[]>((resolve, reject) => {
    api(token)
      .get(`/widgets/${assetClass}/?${stringParams}`)
      .then((response) =>
        decodeResponse(securityRouteModesDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function getSecurityRouteLayouts(
  token: string,
  assetClass: string,
  params: Params,
): Promise<SecurityRouteLayout[]> {
  const stringParams = stringifyParams(params);
  return new Promise<SecurityRouteLayout[]>((resolve, reject) => {
    api(token)
      .get(`/layouts/${assetClass}/?${stringParams}`)
      .then((response) =>
        decodeResponse(securityRouteLayoutsDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function postSecurityRouteLayout(
  token: string,
  assetClass: string,
  data: Omit<SecurityRouteLayout, 'id' | 'created_by' | 'created_at' | 'order'>,
): Promise<SecurityRouteLayout> {
  return new Promise<SecurityRouteLayout>((resolve, reject) => {
    api(token)
      .post(`/layouts/${assetClass}/`, data)
      .then((response) =>
        decodeResponse(securityRouteLayoutDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function patchSecurityRouteLayout(
  token: string,
  assetClass: string,
  id: string,
  data: Partial<SecurityRouteLayout>,
): Promise<SecurityRouteLayout> {
  return new Promise<SecurityRouteLayout>((resolve, reject) => {
    api(token)
      .patch(`/layouts/${assetClass}/${id}/`, data)
      .then((response) =>
        decodeResponse(securityRouteLayoutDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function deleteSecurityRouteLayout(
  token: string,
  assetClass: string,
  id: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/layouts/${assetClass}/${id}/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function patchModel(
  token: string,
  version: string,
  defaultScenarios: number[],
): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, {
        preferences: {
          model_version: version,
          scenarios: defaultScenarios,
          scenarios_with_baselines: defaultScenarios,
        },
      })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchDisplayLanguage(token: string, locale: string): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences: { display_language: locale } })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchAccept(token: string): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences: { disclaimer_accepted: true } })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchSelectedScenarios(
  token: string,
  preferences: Partial<Preferences>,
): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchPatPathway(token: string, data: any): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences: { pat_pathways_parameters: data } })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchWarmingLevel(
  token: string,
  data: { [key: number]: SavedWarmingLevel },
): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences: { warming_level: data } })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchPortfolioFilters(
  token: string,
  data: SettingsFilters,
): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences: { filters: data } })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function patchLocationExplorerLayouts(
  token: string,
  data: LocationExplorerMetrics,
): Promise<Preferences> {
  return new Promise<Preferences>((resolve, reject) => {
    api(token)
      .patch(`/user_properties/`, { preferences: { location_explorer_metrics: data } })
      .then((response) =>
        decodeResponse(settingsDecoder, response.data, reject).then(resolve).catch(reject),
      );
  });
}

export async function getSavedFilters(token: string, params: Params): Promise<SavedFilter[]> {
  return new Promise<SavedFilter[]>((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/bookmarks/?${stringParams}`)
      .then((response) =>
        decodeResponse(savedFiltersDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function postSavedFilter(
  token: string,
  data: SavedFilterPostData,
): Promise<SavedFilter> {
  return new Promise<SavedFilter>((resolve, reject) => {
    api(token)
      .post('/bookmarks/', data)
      .then((response) =>
        decodeResponse(savedFilterDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function putSavedFilter(
  token: string,
  id: number,
  data: SavedFilterPostData,
): Promise<SavedFilter> {
  return new Promise<SavedFilter>((resolve, reject) => {
    api(token)
      .put(`/bookmarks/${id}/`, data)
      .then((response) =>
        decodeResponse(savedFilterDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function deleteSavedFilter(token: string, id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/bookmarks/${id}/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function getExports(token: string): Promise<BulkFileInfo[]> {
  return new Promise<BulkFileInfo[]>((resolve, reject) => {
    api(token)
      .get(`/exports/`)
      .then((response) =>
        decodeResponse(bulkFileInfosDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function getExport(token: string, id: number): Promise<BulkFileInfo> {
  return new Promise<BulkFileInfo>((resolve, reject) => {
    api(token)
      .get(`/exports/${id}/`)
      .then((response) =>
        decodeResponse(bulkFileInfoDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function getExportsRowCount(token: string, data: BulkFileParams): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    api(token)
      .post(`/exports/rowcount/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function downloadFile(token: string, url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .get(url)
      .then((res) => {
        resolve(true);
        window.open(res.data, '_blank');
      })
      .catch((error) => reject(error.response));
  });
}

export async function downloadBlobAsCSV(
  token: string,
  url: string,
  fileName: string,
  blobType: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: blobType });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.setAttribute('style', 'display: none');
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      })
      .catch((error) => reject(error.response));
  });
}

export async function postExport(
  token: string,
  data: Partial<BulkFileInfo>,
): Promise<BulkFileInfo> {
  return new Promise<BulkFileInfo>((resolve, reject) => {
    api(token)
      .post('/exports/', data)
      .then((response) =>
        decodeResponse(bulkFileInfoDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function putExport(
  token: string,
  id: number,
  data: SavedFilterPostData,
): Promise<BulkFileInfo> {
  return new Promise<BulkFileInfo>((resolve, reject) => {
    api(token)
      .put(`/exports/${id}/`, data)
      .then((response) =>
        decodeResponse(bulkFileInfoDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function patchExport(
  token: string,
  id: number,
  data: Partial<BulkFileInfo>,
): Promise<BulkFileInfo> {
  return new Promise<BulkFileInfo>((resolve, reject) => {
    api(token)
      .patch(`/exports/${id}/`, data)
      .then((response) =>
        decodeResponse(bulkFileInfoDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function deleteExport(token: string, id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/exports/${id}/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function getScenario(token: string, mode: string, params: Params): Promise<any> {
  return new Promise((resolve, reject) => {
    const stringParams = stringifyParams(params);
    api(token)
      .get(`/scenario_data/${mode}/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, mode));
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => reject(error.response));
  });
}

export async function getUserInfo(token: string): Promise<UserInfo> {
  return new Promise<UserInfo>((resolve, reject) => {
    api(token)
      .get(`/userinfo/`)
      .then((response) =>
        decodeResponse(userInfoDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function getMetrics(token: string, params?: Params): Promise<Metric[]> {
  const stringParams = params ? `?${stringifyParams(params)}` : '';
  return new Promise<Metric[]>((resolve, reject) => {
    api(token)
      .get(`/metrics/${stringParams}`)
      .then((response) =>
        decodeResponse(metricsDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function getIndicatorData(
  dataset: string,
  indicator: string,
  scenario: string,
  model: string,
  coords: Coordinates,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api('')
      .get(
        `https://climate-backend.analytics.planetrics.com/api/v1/indicator-data/${dataset}/${indicator}/${model}/${scenario}/${coords.lat}/${coords.lng}`,
      )
      .then((response) =>
        decodeResponse(indicatorDataDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function createApiKey(token: string): Promise<ApiKeyPost> {
  return new Promise<ApiKeyPost>((resolve, reject) => {
    api(token)
      .post('/api_keys/')
      .then((response) =>
        decodeResponse(apiKeyPostDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function getApiKeys(token: string): Promise<ApiKey[]> {
  return new Promise<ApiKey[]>((resolve, reject) => {
    api(token)
      .get(`/api_keys/`)
      .then((response) => {
        decodeResponse(apiKeysDecoder, response.data, reject).then(resolve).catch(reject);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function patchApiKey(
  token: string,
  id: number,
  data: Partial<ApiKey>,
): Promise<ApiKey> {
  return new Promise<ApiKey>((resolve, reject) => {
    api(token)
      .patch(`/api_keys/${id}/`, data)
      .then((response) =>
        decodeResponse(apiKeyDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.response));
  });
}

export async function deleteApiKey(token: string, id: number): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/api_keys/${id}/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function getPathwayOptions(token: string): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .get(`/pat/proxy/corporate/pathway-parameters-options/`)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response));
  });
}

export async function getConvergenceYearOptions(token: string, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/pat/proxy/corporate/convergence-year-options/`, data)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response));
  });
}

export async function getMapboxState(token: string, params: Params): Promise<MapboxState> {
  const stringParams = stringifyParams(params);
  return new Promise<MapboxState>((resolve, reject) => {
    api(token)
      .get(`/tile/centroid/?${stringParams}`)
      .then((response) => {
        decodeResponse(mapboxStateDecoder, response.data, reject).then(resolve).catch(reject);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function postActivitiesPreview(
  token: string,
  id: number,
  data: FormData,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/activities/upload-preview/`, data)
      .then((response) =>
        decodeResponse(fundImportPreviewDecoder, response.data, reject).then(resolve).catch(reject),
      )
      .catch((error) => reject(error.message));
  });
}

export async function postSectorsPreview(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/sectors/preview/`, data)
      .then((response) =>
        decodeResponse(modSectorsRegionsPreviewDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((error) => reject(error.response.data));
  });
}

export async function postRegionsPreview(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/regions/preview/`, data)
      .then((response) =>
        decodeResponse(modSectorsRegionsPreviewDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((error) => reject(error.response.data));
  });
}

export async function postEmissionsTargetPreview(
  token: string,
  id: number,
  data: FormData,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/emissions_targets/preview/`, data)
      .then((response) =>
        decodeResponse(modSectorsRegionsPreviewDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((error) => reject(error.response.data));
  });
}

export async function postRevenueTargetsPreview(
  token: string,
  id: number,
  data: FormData,
): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/revenue_share_targets/preview/`, data)
      .then((response) =>
        decodeResponse(modSectorsRegionsPreviewDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((error) => reject(error.response.data));
  });
}

export async function postActivities(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/activities/upload-persist/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}

export async function postSectors(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/sectors/upload/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}

export async function postRegions(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/regions/upload/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}

export async function postEmissionTargets(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/emissions_targets/upload/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}

export async function postRevenueTargets(token: string, id: number, data: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .post(`/funds/${id}/corporate_mod/revenue_share_targets/upload/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}

export async function getActivities(token: string, id: number, params: Params): Promise<Holdings> {
  const stringParams = stringifyParams({ ...{}, ...params });

  return new Promise<Holdings>((resolve, reject) => {
    api(token)
      .get(`/funds/${id}/activities/?${stringParams}`, {
        responseType: params.format === 'csv' ? 'blob' : 'json',
      })
      .then((response) => {
        if (params.format === 'csv') {
          FileDownload(response.data, generateFileName(response.headers, 'activities'));
        } else {
          return decodeResponse(corporateActivitiesDecoder, response.data, reject)
            .then(resolve)
            .catch(reject);
        }
      })
      .catch((err) => reject(err.response));
  });
}

export async function getRouteMessages(token: string): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .get('/messages/')
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });
}

export async function deleteRouteMessage(token: string, id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    api(token)
      .delete(`/messages/${id}/`)
      .then(resolve)
      .catch((error) => reject(error.response));
  });
}

export async function getUsersOfMyOrg(
  token: string,
  params: Params,
  signal?: AbortSignal,
): Promise<UsersResponse> {
  const stringParams = stringifyParams(params);
  return new Promise((resolve, reject) => {
    api(token)
      .get(`/user_list/?${stringParams}`, { signal })
      .then((response) => {
        decodeResponse(usersResponseDecoder, response.data, reject).then(resolve).catch(reject);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function getCompanyCharacteristics(
  token: string,
  params: Params,
): Promise<CompanyCharacteristics[]> {
  const stringParams = stringifyParams(params);
  return new Promise<CompanyCharacteristics[]>((resolve, reject) => {
    api(token)
      .get(`/equity/characteristics/?${stringParams}`)
      .then((response) =>
        decodeResponse(companyCharacteristicsResponseDecoder, response.data, reject)
          .then(resolve)
          .catch(reject),
      )
      .catch((err) => reject(err.response));
  });
}

export async function postPhysicalRiskDjangoBackend(
  token: string,
  riskType: string,
  data: any,
  params: Params,
  config: AxiosRequestConfig,
  modelVersion: string,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const stringParams = stringifyParams({ profiler: false, format: params.format });
    api(token)
      .post(`/location/proxy/${modelVersion}/risk-assessment/${riskType}/?${stringParams}`, data, {
        ...config,
        responseType: params.format === 'csv' ? 'blob' : 'json',
      })
      .then((response) => {
        if (params.format === 'csv') {
          resolve(response.data);
        } else {
          resolve(response.data.data);
        }
      })
      .catch((error) => reject(error.response));
  });
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enGB from './locales/en-gb.json';
import enUS from './locales/en-us.json';
import es from './locales/es.json';
import ru from './locales/ru.json';

const resources = {
  'en-GB': enGB,
  'en-US': enUS,
  es,
  ru,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en-US',
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br'],
  },
});

export default i18n;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PREFIX = 'DownloadNotification';

export const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  button: `${PREFIX}-button`,
};

export const StyledNotification = styled(Box)(({ theme }) => ({
  width: '335px',
  backgroundColor: '#fff',
  padding: theme.spacing(2, 1),
  position: 'absolute',
  right: 16,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  zIndex: 9,

  '& header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      margin: 0,
      marginLeft: theme.spacing(1),
      fontWeight: 500,
      color: '#222',
    },
    '& .title-box': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .close-icon': {
      fontSize: 16,
      cursor: 'pointer',
    },
  },

  '& .contentBox': {
    marginLeft: theme.spacing(4),
    color: theme.palette.grey[500],

    '& p': {
      fontSize: 14,
    },
  },

  '& button': {
    fontWeight: 400,
    marginTop: theme.spacing(1),
  },
}));

import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './i18n';
import { theme } from './theme';
import { ConfirmServiceProvider } from './context/confirm';
import { PendingFundsProvider } from './context/pendingFunds';
import Sidebar from './components/Sidebar';
import { UserPropertiesProvider } from '@context/userProperties';
import { ScenarioSelectProvider } from './context/scenarioSelect';
import { DownloadsNotificationProvider } from './context/downloadsNotification';
import FullPageLoading from './components/FullPageLoading';
import LoginScreen from './screens/LoginScreen';
import ErrorScreen from './components/ErrorScreen';
import AppRoutes from './appRoutes';
import { SidebarStateProvider } from '@context/sidebarState';
import { RouteMessagesProvider } from '@context/routeMessages';
import { ExportNotificationProvider } from '@context/exportsNotification';

const PREFIX = 'App';

const classes = {
  content: `${PREFIX}-content`,
};

const StyledApp = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  background: theme.palette.primary.dark,
  backgroundSize: '800px 1440px',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  [`& .${classes.content}`]: {
    position: 'relative',
    padding: 0,
    boxSizing: 'border-box',
    background:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(to bottom, #07305d, #042142), #07305d'
        : theme.palette.primary.light,
    flex: '1 1 auto',
    height: '100vh',
    overflow: 'auto',
  },
}));

const App = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useAuth0();
  const queryClient = new QueryClient();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && navigate) {
      navigate('/login', {
        state: {
          appState: {
            returnTo: window.location.pathname !== '/logout' ? window.location.pathname : '',
          },
        },
        replace: true,
      });
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, navigate]);

  useEffect(() => {
    if (window.location.pathname === '/logout' && !isLoading) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [pathname, isLoading, logout]);

  useEffect(() => {
    if (user?.org_id) {
      localStorage.setItem('organization_id', String(user.org_id));
    }
  }, [user]);

  if (error) {
    return <ErrorScreen message={error.message} />;
  }

  if (isLoading || pathname === '/logout') {
    return <FullPageLoading />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ConfirmServiceProvider>
        {!isAuthenticated ? (
          <LoginScreen />
        ) : (
          <UserPropertiesProvider>
            <QueryClientProvider client={queryClient}>
              <ScenarioSelectProvider>
                <DownloadsNotificationProvider>
                  <ExportNotificationProvider>
                    <PendingFundsProvider>
                      <RouteMessagesProvider>
                        <SidebarStateProvider>
                          <StyledApp>
                            <Sidebar />
                            <Grid container id="app-content" className={classes.content}>
                              <AppRoutes />
                            </Grid>
                          </StyledApp>
                        </SidebarStateProvider>
                      </RouteMessagesProvider>
                    </PendingFundsProvider>
                  </ExportNotificationProvider>
                </DownloadsNotificationProvider>
              </ScenarioSelectProvider>
            </QueryClientProvider>
          </UserPropertiesProvider>
        )}
      </ConfirmServiceProvider>
    </DndProvider>
  );
};

export default App;

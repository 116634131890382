import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DialogTitle } from '@components/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { classes, StyledDialog } from '../Layout/styled';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { RouteMessage } from '@models';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  messages: RouteMessage[];
  onClose: () => void;
  isModalOpened: boolean;
  onDelete?: (id: string) => void;
};

export const FeatureDialog = ({ messages, onClose, onDelete, isModalOpened }: Props) => {
  const { t } = useTranslation(['common']);
  const { pathname } = useLocation();

  const [checked, setChecked] = useState(false);
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);

  const handleChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  }, []);

  const handleSubmit = useCallback(
    (id: string) => {
      if (checked) {
        onDelete?.(id);
        setChecked(false);
        return;
      }
      setActiveMessageIndex(activeMessageIndex + 1);
    },
    [activeMessageIndex, checked, onDelete],
  );

  useEffect(() => {
    if (messages[activeMessageIndex] === undefined) {
      onClose();
    }
  }, [activeMessageIndex, messages, onClose]);

  useEffect(() => {
    setActiveMessageIndex(0);
  }, [pathname]);

  const contentWithNewlines = messages[activeMessageIndex]?.content
    .split(/\\n|\n/)
    .map((item, index) => (
      <Typography className="splited-text" key={`content=${index}`}>
        {item}
      </Typography>
    ));

  return messages[activeMessageIndex] !== undefined ? (
    <StyledDialog className="none-blurred route-message-modal" open={isModalOpened}>
      <DialogTitle title={messages[activeMessageIndex].title} showCloseIcon={false} />
      <DialogContent className={clsx(classes.dialogContent, 'small-width', 'noMargin')}>
        {contentWithNewlines}
      </DialogContent>
      <FormGroup className="checkbox-container">
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChecked} />}
          label={t(`Don't show message again`)}
        />
      </FormGroup>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => handleSubmit(messages[activeMessageIndex].id)}
      >
        {t('Continue')}
      </Button>
    </StyledDialog>
  ) : null;
};
